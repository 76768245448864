import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import odoo from "./modules/odoo";

const store = createStore({
  modules: {
    auth,
    odoo
  },
  state() {
    return {
      momTemplateFileUrl: process.env.VUE_APP_MOM_TEMPLATE_FILE_URL,
    };
  },
  getters: {
    getMomTemplateFileUrl(state) {
      return state.momTemplateFileUrl;
    },
  },
  actions: {},
  mutations: {},
  plugins: [createPersistedState({
    paths: ["auth.user", "auth.token"],
  })],
});

export default store;
